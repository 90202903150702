import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import footerBg from "../../images/footer_bg.png";
import footerwebpBg from "../../images/footer_bg.webp";
import "./Footer.scss";

const currentYear = new Date().getUTCFullYear();

const Footer = () => (
  <footer className="footer bg-overlay">
    <div className="bg-overlay__media">
      <picture>
        <source srcSet={footerwebpBg} type="image/webp" />
        <source srcSet={footerBg} type="image/png" />
        <Image src={footerBg} alt="footer" fluid width={1910} height={632} />
      </picture>
    </div>
    <div className="map-pointer">
      <span className="map-pointer__animated map-pointer--us"></span>
      <span className="map-pointer__animated map-pointer--uk"></span>
      <span className="map-pointer__animated map-pointer--india"></span>
      <span className="map-pointer__animated map-pointer--israel"></span>
      <span className="map-pointer__animated map-pointer--italy"></span>
      <span className="map-pointer__animated map-pointer--netherlands"></span>
      <span className="map-pointer__animated map-pointer--ukraine"></span>
      <span className="map-pointer__animated map-pointer--spain"></span>
      <span className="map-pointer__animated map-pointer--dubai"></span>
    </div>

    <div className="footer_top">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-md-4 col-12">
            <div className="footer_logo">
              <Link to="/">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/logo_final_dark_bg.png"
                  }
                  alt=""
                  className="img-fluid"
                  width={250}
                  height={118}
                />
              </Link>
            </div>
            <div className="footer_dmca">
              <a
                href="//www.dmca.com/Protection/Status.aspx?ID=cfe53efe-9c86-4a2d-80ad-178095a7135e"
                title="DMCA.com Protection Status"
                className="dmca-badge"
              >
                <img
                  src="https://images.dmca.com/Badges/dmca-badge-w250-5x1-09.png?ID=cfe53efe-9c86-4a2d-80ad-178095a7135e"
                  alt="DMCA.com Protection Status"
                  loading="lazy"
                />
              </a>
              <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
            </div>

            <a
              href="https://review.clutch.co/review/?provider_id=1649513"
              className="footer-clutch"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer-clutch__logo">
                <img
                  src={process.env.PUBLIC_URL + "/images/footer-clutch-1.png"}
                  alt="clutch logo"
                ></img>
              </div>
            </a>
            <div
              className="goodfirm-widget"
              data-widget-type="goodfirms-widget-t8"
              data-widget-pattern="poweredby-star"
              data-height="100"
              data-company-id="102873"
            ></div>
          </div>
          <div className="col-xl-8 col-md-8 col-12">
            <div className="footer_link_contact_wrapper">
              <div className="footer_links">
                <ul>
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  {/* <li>
                    <Link to="/technologies">Technology</Link>
                  </li> */}
                  <li>
                    <a
                      href="https://forms.gle/vZ2sduLBcvTeZ61L9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer_contact_info">
                <div className="footer_contact-block">
                  <p className="footer_contact-que">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="rightMargin"
                    >
                      <path
                        d="M3 6.88848L9.13713 10.9798C9.65964 11.3282 10.3404 11.3282 10.8629 10.9798L17 6.88848M4.55556 15.4439H15.4444C16.3036 15.4439 17 14.7475 17 13.8884V6.11071C17 5.25161 16.3036 4.55518 15.4444 4.55518H4.55556C3.69645 4.55518 3 5.25161 3 6.11071V13.8884C3 14.7475 3.69645 15.4439 4.55556 15.4439Z"
                        stroke="#fd8061"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Background Verifications
                  </p>
                  <p className="footer_contact-ans leftMargin">
                    <a href="mailto:bgv@equestsolutions.net">
                      bgv@equestsolutions.net
                    </a>
                  </p>
                </div>
                <div className="footer_contact-block">
                  <p className="footer_contact-que">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="rightMargin"
                    >
                      <path
                        d="M3 6.88848L9.13713 10.9798C9.65964 11.3282 10.3404 11.3282 10.8629 10.9798L17 6.88848M4.55556 15.4439H15.4444C16.3036 15.4439 17 14.7475 17 13.8884V6.11071C17 5.25161 16.3036 4.55518 15.4444 4.55518H4.55556C3.69645 4.55518 3 5.25161 3 6.11071V13.8884C3 14.7475 3.69645 15.4439 4.55556 15.4439Z"
                        stroke="#fd8061"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Looking for a Job?
                  </p>
                  <p className="footer_contact-ans leftMargin">
                    <a href="mailto:hr@equestsolutions.net">
                      hr@equestsolutions.net
                    </a>
                    {/* <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                      |
                    </span>
                    <a href="tel:+917948909475">+91-79-4890-9475</a> */}
                  </p>
                </div>

                <div className="footer_contact-block">
                  <p className="footer_contact-que">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="rightMargin"
                    >
                      <path
                        d="M3 6.88848L9.13713 10.9798C9.65964 11.3282 10.3404 11.3282 10.8629 10.9798L17 6.88848M4.55556 15.4439H15.4444C16.3036 15.4439 17 14.7475 17 13.8884V6.11071C17 5.25161 16.3036 4.55518 15.4444 4.55518H4.55556C3.69645 4.55518 3 5.25161 3 6.11071V13.8884C3 14.7475 3.69645 15.4439 4.55556 15.4439Z"
                        stroke="#fd8061"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Discuss a Project? <span>(We never SPAM you)</span>
                  </p>
                  <p className="footer_contact-ans leftMargin">
                    <a href="mailto:sales@equestsolutions.net">
                      sales@equestsolutions.net
                    </a>
                    {/* <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                      |
                    </span>
                    <a href="tel:+917948909475">+91-79-4890-9475</a> */}
                  </p>
                </div>

                <div className="footer_contact-block">
                  <p className="footer_contact-que">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 473.806 473.806"
                      className="rightMargin"
                    >
                      <g>
                        <path
                          d="M374.456 293.506c-9.7-10.1-21.4-15.5-33.8-15.5-12.3 0-24.1 5.3-34.2 15.4l-31.6 31.5c-2.6-1.4-5.2-2.7-7.7-4-3.6-1.8-7-3.5-9.9-5.3-29.6-18.8-56.5-43.3-82.3-75-12.5-15.8-20.9-29.1-27-42.6 8.2-7.5 15.8-15.3 23.2-22.8 2.8-2.8 5.6-5.7 8.4-8.5 21-21 21-48.2 0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5-6-6.2-12.3-12.6-18.8-18.6-9.7-9.6-21.3-14.7-33.5-14.7s-24 5.1-34 14.7l-.2.2-34 34.3c-12.8 12.8-20.1 28.4-21.7 46.5-2.4 29.2 6.2 56.4 12.8 74.2 16.2 43.7 40.4 84.2 76.5 127.6 43.8 52.3 96.5 93.6 156.7 122.7 23 10.9 53.7 23.8 88 26 2.1.1 4.3.2 6.3.2 23.1 0 42.5-8.3 57.7-24.8.1-.2.3-.3.4-.5 5.2-6.3 11.2-12 17.5-18.1 4.3-4.1 8.7-8.4 13-12.9 9.9-10.3 15.1-22.3 15.1-34.6 0-12.4-5.3-24.3-15.4-34.3l-54.9-55.1zm35.8 105.3c-.1 0-.1.1 0 0-3.9 4.2-7.9 8-12.2 12.2-6.5 6.2-13.1 12.7-19.3 20-10.1 10.8-22 15.9-37.6 15.9-1.5 0-3.1 0-4.6-.1-29.7-1.9-57.3-13.5-78-23.4-56.6-27.4-106.3-66.3-147.6-115.6-34.1-41.1-56.9-79.1-72-119.9-9.3-24.9-12.7-44.3-11.2-62.6 1-11.7 5.5-21.4 13.8-29.7l34.1-34.1c4.9-4.6 10.1-7.1 15.2-7.1 6.3 0 11.4 3.8 14.6 7l.3.3c6.1 5.7 11.9 11.6 18 17.9 3.1 3.2 6.3 6.4 9.5 9.7l27.3 27.3c10.6 10.6 10.6 20.4 0 31-2.9 2.9-5.7 5.8-8.6 8.6-8.4 8.6-16.4 16.6-25.1 24.4-.2.2-.4.3-.5.5-8.6 8.6-7 17-5.2 22.7l.3.9c7.1 17.2 17.1 33.4 32.3 52.7l.1.1c27.6 34 56.7 60.5 88.8 80.8 4.1 2.6 8.3 4.7 12.3 6.7 3.6 1.8 7 3.5 9.9 5.3.4.2.8.5 1.2.7 3.4 1.7 6.6 2.5 9.9 2.5 8.3 0 13.5-5.2 15.2-6.9l34.2-34.2c3.4-3.4 8.8-7.5 15.1-7.5 6.2 0 11.3 3.9 14.4 7.3l.2.2 55.1 55.1c10.3 10.2 10.3 20.7.1 31.3zM256.056 112.706c26.2 4.4 50 16.8 69 35.8s31.3 42.8 35.8 69c1.1 6.6 6.8 11.2 13.3 11.2.8 0 1.5-.1 2.3-.2 7.4-1.2 12.3-8.2 11.1-15.6-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3 3.7-15.6 11s3.5 14.4 10.9 15.6zM473.256 209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2 3.7-15.5 11-1.2 7.4 3.7 14.3 11.1 15.6 46.6 7.9 89.1 30 122.9 63.7 33.8 33.8 55.8 76.3 63.7 122.9 1.1 6.6 6.8 11.2 13.3 11.2.8 0 1.5-.1 2.3-.2 7.3-1.1 12.3-8.1 11-15.4z"
                          fill="#fd8061"
                          opacity="1"
                          data-original="#fd8061"
                          className=""
                        ></path>
                      </g>
                    </svg>
                    Get in Touch
                  </p>

                  <p className="footer_contact-ans leftMargin">
                    {/* <a href="mailto:jobs@equestsolutions.net">jobs@equestsolutions.net</a> */}

                    <a href="tel:+917940059475">+91-79-4005-9475</a>
                    <br />
                    <a href="tel:+917948909475">+91-79-4890-9475</a>
                  </p>
                </div>

                <div className="footer_contact-block">
                  <p className="footer_contact-que">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="rightMargin"
                    >
                      <path
                        d="M14.1006 13.4469C13.4217 14.1258 12.0019 15.5456 11.0249 16.5225C10.4587 17.0887 9.54172 17.089 8.97555 16.5229C8.01561 15.5629 6.6233 14.1706 5.89954 13.4469C3.63489 11.1822 3.63489 7.51049 5.89954 5.24585C8.1642 2.9812 11.8359 2.9812 14.1006 5.24585C16.3653 7.51049 16.3653 11.1822 14.1006 13.4469Z"
                        stroke="#fd8061"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.1747 9.34635C12.1747 10.5474 11.2011 11.521 10.0001 11.521C8.79905 11.521 7.82544 10.5474 7.82544 9.34635C7.82544 8.14534 8.79905 7.17173 10.0001 7.17173C11.2011 7.17173 12.1747 8.14534 12.1747 9.34635Z"
                        stroke="#fd8061"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    How about a visit?
                  </p>
                  <p className="footer_contact-ans leftMargin">
                    1305-1312 SKD Surya Icon, 132 Feet Ring Road, <br />
                    AEC Cross Road, opp. Torrent Power, Naranpura, <br />{" "}
                    Ahmedabad, Gujarat 380013
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer_bottom">
      <div className="container">
        <div className="footer_bottom_wrapper">
          <div className="footer_bottom-left">
            <div className="footer_social_links">
              <a
                href="https://www.facebook.com/equestsolutions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/facebook.svg"}
                  alt=""
                  className="img-fluid"
                  width={23}
                  height={23}
                />
              </a>
              <a
                href="https://www.instagram.com/equestsolutions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/insta.svg"}
                  alt=""
                  className="img-fluid"
                  width={23}
                  height={23}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/equest-solutions-india/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/linkedin.svg"}
                  alt=""
                  className="img-fluid"
                  width={23}
                  height={23}
                />
              </a>
              <a
                href="https://twitter.com/equesttweets"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/twiter.svg"}
                  alt=""
                  className="img-fluid"
                  style={{ width: 15 + "px" }}
                  width={23}
                  height={23}
                />
              </a>
            </div>
          </div>
          <div className="footer_bottom-right">
            <p className="footer_copyrights">
              (C) {currentYear}, eQuest Solutions
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
